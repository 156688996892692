<script>
import { mapGetters } from 'vuex';

import MobileGenericDropdown from '@/components/Modal/Mobile/Dropdowns/MobileGenericDropdown';
import MobileTopBar from '@/components/molecules/MobileTopBar';
import Sidebar from '@/components/molecules/Sidebar';
import SidebarMixin from '@/components/molecules/Sidebar/mixins/SidebarMixin';
import { SearchBar } from '~/features/search';

const layoutHeaders = {
  overview: defineAsyncComponent(() =>
    import('~/features/overview/components/Heading'),
  ),
};

export default defineNuxtComponent({
  components: { MobileTopBar, Sidebar, SearchBar, MobileGenericDropdown },
  mixins: [SidebarMixin],
  props: {
    user: {
      type: Object,
      required: true,
      default: () => {},
    },
    navigation: {
      type: Array,
      required: true,
      default: () => [],
    },
    locations: {
      type: Array,
      required: true,
      default: () => [],
    },
    checkedLocation: {
      type: Number,
      required: true,
      default: 0,
    },
    currentModule: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      activeItemTitle: '',
    };
  },
  computed: {
    layoutHeader() {
      return layoutHeaders[this.currentModule];
    },
    ...mapGetters('MobileDropdownModalStore', [
      'dropdownOpened',
      'dropdownType',
    ]),
  },
  methods: {
    setSelectedNavigationItemTitle() {
      if (
        !this.$refs.sidebar
        || !this.$refs.sidebar.selectedNavigationItem.children?.length
      )
        this.activeItemTitle = '';

      this.activeItemTitle = this.$refs.sidebar.selectedNavigationItem.label;
    },
    onSecondNavigationLevelElementClick() {
      this.setSecondSidebarIsCollapsed(true);
    },
    onArrowLeftClick() {
      this.setSidebarIsCollapsed(false);
    },
    onNavigationItemClick(item) {
      this.setSecondSidebarIsCollapsed(!item.children?.length);
      this.setSidebarIsCollapsed(true);
      this.setSelectedNavigationItemTitle();
    },
    onMenuClick() {
      this.setSidebarIsCollapsed(false);
      this.setSecondSidebarIsCollapsed(true);
    },
    onClose() {
      this.setSidebarIsCollapsed(true);
      this.setSecondSidebarIsCollapsed(true);
    },
    onCreate() {
      this.$emit('onCreateNew');
    },
    onLocationChanged(location) {
      this.$emit('onLocationChanged', location);
    },
  },
});
</script>

<template>
  <div class="flex h-full flex-col">
    <MobileTopBar
      :sidebar-collapsed="isSidebarCollapsed"
      :secondary-sidebar-collapsed="isSecondSidebarCollapsed"
      :active-item-title="activeItemTitle"
      class="z-75"
      @on-menu-item-click="onMenuClick"
      @on-arrow-left-click="onArrowLeftClick"
      @on-close="onClose"
      @on-create="onCreate"
    />

    <div class="h-full">
      <div
        class="absolute flex h-full w-full flex-col px-6 pb-6 pt-16 transition-all duration-200"
      >
        <div class="flex flex-row">
          <SearchBar
            label=""
            :placeholder="__('What are you looking for?')"
            :selected-location="checkedLocation"
            class="py-6"
          />
        </div>
        <KeepAlive :max="3">
          <div class="flex flex-1 flex-col">
            <component :is="layoutHeader" />

            <NuxtPage />
          </div>
        </KeepAlive>
      </div>
      <div class="z-5 h-full">
        <Sidebar
          ref="sidebar"
          device-type="mobile"
          :user="user"
          :navigation="navigation"
          :locations="locations"
          :checked-location="checkedLocation"
          @on-location-changed="onLocationChanged"
          @on-navigation-item-click="onNavigationItemClick"
          @on-second-navigation-level-element-click="
            onSecondNavigationLevelElementClick
          "
        />
      </div>
    </div>

    <MobileGenericDropdown v-if="dropdownOpened" :type="dropdownType" />
  </div>
</template>
